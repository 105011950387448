import { ROUTES } from 'constants';
import { Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import OnboardingLayout from 'layouts/OnboardingLayout';
import DashboardLayout from 'layouts/DashboardLayout';
import PagesLayout from 'layouts/PagesLayout';
import { useSelector } from 'react-redux';

const Login = lazy(() => import('pages/Auth/Login'));
const Register = lazy(() => import('pages/Auth/Register'));
const RecoverPassword = lazy(() => import('pages/Auth/RecoverPassword'));
const ErrorPage = lazy(() => import('pages/General/ErrorPage'));
const Home = lazy(() => import('pages/Dashboard'));
const Student = lazy(() => import('pages/Dashboard/Student'));
const Cart = lazy(() => import('pages/General/Cart'));
const Tutor = lazy(() => import('pages/Dashboard/Expert'));
const CoursePreview = lazy(() => import('pages/General/CoursePreview'));
const CourseLearning = lazy(() => import('pages/Dashboard/Student/CourseLearning'));
const CoursePage = lazy(() => import('pages/Dashboard/Expert/Courses'));
const CreateCourse = lazy(() => import('pages/Dashboard/Expert/CreateCourse'));
const CertificatePage = lazy(() => import('pages/Dashboard/Student/CertificatePage'));
const EarningPage = lazy(() => import('pages/EarningPage'));
const PayoutPage = lazy(() => import('pages/PayoutPage'));
const Member = lazy(() => import('pages/Dashboard/Member'));
const Admin = lazy(() => import('pages/Dashboard/Admin'));
const Courses = lazy(() => import('pages/General/Courses'));
const MyCourses = lazy(() => import('pages/Dashboard/Student/MyCourses'));
const Trainings = lazy(() => import('pages/Dashboard/Admin/Trainings'));
const Training = lazy(() => import('pages/Dashboard/Admin/Training'));
const HomeLanding = lazy(() => import('pages/General/HomeLanding'));
const AboutUs = lazy(() => import('pages/General/AboutUs'));
const ContactUs = lazy(() => import('pages/General/ContactUs'));
const Blog = lazy(() => import('pages/General/BlogPage'));
const Notifications = lazy(() => import('pages/General/Notifications'));
const ProfilePage = lazy(() => import('pages/Dashboard/ProfilePage'));
const PricingPage = lazy(() => import('pages/General/PricingPage'));
const QuizPage = lazy(() => import('pages/QuizPage'));
const ForumPage = lazy(() => import('pages/ForumPage'));
const ForumPages = lazy(() => import('pages/ForumPages'));
const Payment = lazy(() => import('pages/Dashboard/Payment'));

//  filter by free and paid and all
// filter by categories is also debatable
// Featured courses/ latest courses

function App() {
    const { authRole } = useSelector((state) => state.app);

    return (
        <Suspense fallback={<div className="fixed left-0 bottom-0 w-screen h-screen animate-pulse" />}>
            <Routes>
                <Route element={<OnboardingLayout />}>
                    <Route path={ROUTES.LOGIN} element={<Login />} />
                    <Route path={ROUTES.ADMIN_LOGIN} element={<Login />} />
                    {authRole !== 'admin' && <Route path={ROUTES.REGISTER} element={<Register />} />}
                    <Route path={ROUTES.RECOVER} element={<RecoverPassword />} />
                </Route>

                <Route element={<PagesLayout />}>
                    <Route path={ROUTES.HOMELANDING} element={<HomeLanding />} />
                    <Route path={ROUTES.ABOUTUS} element={<AboutUs />} />
                    <Route path={ROUTES.CONTACTUS} element={<ContactUs />} />
                    <Route path={ROUTES.BLOG} element={<Blog />} />
                    <Route path={ROUTES.PRICING} element={<PricingPage />} />
                    <Route path={ROUTES.CART} element={<Cart />} />
                    <Route path={ROUTES.COURSE_PREVIEW} element={<CoursePreview />} />
                    <Route path={ROUTES.TRAINING_COURSE} element={<Training />} />
                    <Route path={ROUTES.COURSES} element={<Courses />} />
                </Route>

                <Route element={<DashboardLayout />}>
                    <Route path={ROUTES.HOMEPAGE} element={<Home />} />
                    <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
                    <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
                    <Route path={ROUTES.FORUMS} element={<ForumPages />} />
                    <Route path={ROUTES.FORUM} element={<ForumPage />} />
                    <Route path={ROUTES.COURSE_LEARNING} element={<CourseLearning />} />

                    {authRole === 'achiever' && (
                        <>
                            <Route path={ROUTES.STUDENT} element={<Student />} />
                            <Route path={ROUTES.STUDENT_CERTIFICATE} element={<CertificatePage />} />
                            <Route path={ROUTES.MY_COURSES} element={<MyCourses />} />
                            <Route path={ROUTES.QUIZ} element={<QuizPage />} />
                        </>
                    )}

                    {authRole === 'expert' && (
                        <>
                            <Route path={ROUTES.TUTOR} element={<Tutor />} />
                            <Route path={ROUTES.TUTOR_COURSES} element={<CoursePage />} />
                            <Route path={ROUTES.TUTOR_CREATE_COURSE} element={<CreateCourse />} />
                            <Route path={ROUTES.TUTOR_EARNING} element={<EarningPage />} />
                            <Route path={ROUTES.TUTOR_PAYOUT} element={<PayoutPage />} />
                            <Route path={ROUTES.TUTOR_PAYMENT} element={<Payment />} />
                        </>
                    )}

                    {authRole === 'member' && (
                        <>
                            <Route path={ROUTES.MEMBER} element={<Member />} />
                            <Route path={ROUTES.MEMBER_TRAINING} element={<Trainings />} />
                            <Route path={ROUTES.MEMBER_PAYMENT} element={<Payment />} />
                        </>
                    )}

                    {authRole === 'admin' && (
                        <>
                            <Route path={ROUTES.ADMIN} element={<Admin />} />
                            <Route path={ROUTES.ADMIN_TRAINING} element={<Trainings />} />
                        </>
                    )}
                </Route>
                <Route path={'*'} element={<ErrorPage />} />
            </Routes>
        </Suspense>
    );
}

export default App;
