/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from 'react-dom';
import { ROUTES } from 'constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import ProfileDropdown from '../../components/Dropdowns/ProfileDropdown';
import logo from 'assets/images/tli/fav-logo.png';
import NotificationDropdown from '../../components/Dropdowns/NotificationDropdown';
import { setRole, setToggleMobileMenu } from 'redux/slices/appSlice';
import { setNotifications } from 'redux/slices/authSlice';
import { toast } from 'react-toastify';
import getInitials from 'utils/getInitials';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { db } from 'utils/firebase';
import { useEffect } from 'react';
import { formatDateValues } from 'utils/formatDateValues';

const Nav = () => {
    const { toggleMobileMenu, authRole } = useSelector((state) => state.app);
    const [toggleProfileMenu, setToggleProfileMenu] = useState(false);
    const [toggleMenuLogin, setToggleMenuLogin] = useState(false);
    const [toggleNotification, setToggleNotification] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { items } = useSelector((state) => state.basket);
    const { notifications: nots, user } = useSelector((state) => state.auth);
    const isDashboardPages = pathname.includes('/dashboard');

    const fetchUnreadNotifications = async () => {
        const queryText =
            authRole === 'expert'
                ? `notifications/experts/${user.id}`
                : authRole === 'admin'
                ? `notifications/admin/admin`
                : authRole === 'member'
                ? `notifications/members/${user.id}`
                : `notifications/achievers/${user.id}`;

        const q = query(collection(db, queryText), where('status', '==', 'unread'), limit(5));
        const nots = [];
        try {
            const notsDocRef = await getDocs(q);
            notsDocRef.forEach((doc) =>
                nots.push({
                    ...doc.data(),
                    ...formatDateValues(doc.data()),
                })
            );
            nots.length && dispatch(setNotifications(nots));
        } catch (error) {
            toast.error('Fetching notifications failed, please reload the page.');
        }
    };

    useEffect(() => {
        user && fetchUnreadNotifications();
    }, [user]);

    return (
        <nav
            className={`flex justify-between h-max items-center z-[200] bg-white px-[20px] md:px-[30px] drop-shadow-md`}
        >
            {/* left side */}
            <div className="flex items-center">
                {/* mobile menu */}
                <div
                    onClick={() => {
                        dispatch(setToggleMobileMenu());
                    }}
                    className={`md:hidden cursor-pointer w-[20px] shrink-0 sm:mr-[5px] flex flex-col ${
                        !toggleMobileMenu && 'relative'
                    } transition-all duration-300`}
                >
                    <div
                        className={`w-[20px] h-[3px] bg-black rounded-full ${
                            !toggleMobileMenu && 'rotate-[45deg] absolute top-0'
                        } transition-all duration-300`}
                    ></div>
                    <div
                        className={`w-[20px] h-[3px] bg-black rounded-full my-[3px] ${
                            !toggleMobileMenu && 'hidden'
                        } transition-all duration-300`}
                    ></div>
                    <div
                        className={`w-[20px] h-[3px] bg-black rounded-full ${
                            !toggleMobileMenu && 'rotate-[135deg] absolute top-0'
                        } transition-all duration-300`}
                    ></div>
                    {/* <span className="material-icons">menu</span> */}
                </div>
                {/* logo */}
                <Link
                    to={ROUTES.HOMELANDING}
                    className="text-lg py-[5px] font-semibold flex items-center md:mr-[40px] [&_*]:transition-colors group"
                >
                    {/* <span className="material-icons text-3xl ml-4 mr-2 text-primary-2 group-hover:text-primary-2">
                        diversity_2
                    </span> */}
                    <img src={logo} className="mr-1 w-[50px] max-md:ml-4" alt="TLI Skills" />
                    <p className="text-black mr-[40px] group-hover:text-primary-2 shrink-0">TLI Skills</p>
                </Link>

                {/* menus */}
                <nav>
                    <ul className="space-x-[20px] hidden md:flex ">
                        {!isDashboardPages && (
                            <Link to={user ? ROUTES.HOMEPAGE : ROUTES.HOMELANDING}>
                                <li className="flex items-center py-[20px] cursor-pointer hover:text-primary-dark">
                                    <p className="">{user ? 'Dashboard' : 'Home'}</p>
                                </li>
                            </Link>
                        )}
                        <Link to={ROUTES.ABOUTUS}>
                            <li className="flex items-center py-[20px] cursor-pointer hover:text-primary-dark">
                                <p className="">About Us</p>
                            </li>
                        </Link>

                        <Link to={ROUTES.CONTACTUS}>
                            <li className="flex py-[20px] items-center cursor-pointer hover:text-primary-dark">
                                <p className="">Contact Us</p>
                            </li>
                        </Link>
                        <Link to={ROUTES.BLOG}>
                            <li className="flex py-[20px] items-center cursor-pointer hover:text-primary-dark">
                                <p className="">Blog</p>
                            </li>
                        </Link>
                    </ul>
                </nav>
            </div>

            {/* right menus */}
            <div className="flex items-center">
                <span className="material-icons-outlined text-[24px] text-black mx-[20px] cursor-pointer">search</span>

                <span
                    className="relative  after:h-[11.3px] after:w-[11.3px] cursor-pointer ml-auto translate-y-1 "
                    onClick={() =>
                        items.length
                            ? navigate(ROUTES.CART)
                            : toast.error('No course added! Please add a course to your cart.')
                    }
                >
                    <span className="material-icons-outlined text-[26px] text-black/70">shopping_cart</span>
                    <span className="absolute -top-[6px] -right-[4px] rounded-full bg-primary text-white text-[9px] h-[17.3px] w-[18px] pt-[px] text-center font-extrabold border-2 border-white">
                        {items.length}
                    </span>
                </span>

                {!isDashboardPages && !user && (
                    <span
                        onMouseEnter={() => setToggleMenuLogin(true)}
                        onMouseLeave={() => setToggleMenuLogin(false)}
                        className={`${
                            toggleMenuLogin && 'text-primary-dark'
                        } cursor-pointer hover:text-primary-dark py-[20px] translate-x-3.5 ml-3 max-md:hidden`}
                    >
                        <div className="relative">
                            <div className="flex items-center pb-[0]">
                                <p className="">Login</p>
                                <span
                                    className={`material-icons text-[18px] ${
                                        toggleMenuLogin && 'rotate-180 duration-300'
                                    } `}
                                >
                                    expand_more
                                </span>
                            </div>
                            {toggleMenuLogin && <LoginDropDown />}
                        </div>
                    </span>
                )}

                {user && (
                    <>
                        <span className="hidden md:block relative after:h-[11.3px] after:w-[11.3px] md:mx-7 cursor-pointer  translate-y-1">
                            <span
                                onClick={() => {
                                    setToggleNotification(!toggleNotification);
                                    setToggleProfileMenu(false);
                                }}
                                className="material-icons-outlined text-[26px] text-black/70"
                            >
                                notifications
                            </span>

                            {nots && (
                                <span className="absolute -top-[6px] -right-[3px] rounded-full bg-primary text-white text-[9px] h-[17.3px] w-[18px] pt-[px] text-center font-extrabold border-2 border-white">
                                    {nots.length}
                                </span>
                            )}
                            {toggleNotification && nots && (
                                <NotificationDropdown nots={nots} closeNotification={setToggleNotification} />
                            )}
                        </span>

                        {user.photoURL ? (
                            <img
                                src={user.photoURL}
                                alt={user.fullName}
                                className="hidden md:block border rounded-full object-cover ml-[0px] mr-[0px] cursor-pointer text-[8px] text-center overflow-hidden"
                                width={'37px'}
                                height={'37px'}
                                onClick={() => {
                                    setToggleProfileMenu(!toggleProfileMenu);
                                    setToggleNotification(false);
                                }}
                            />
                        ) : (
                            <span
                                className="hidden md:grid rounded-full w-[37px] h-[37px] bg-primary text-white place-items-center font-medium cursor-pointer"
                                onClick={() => {
                                    setToggleProfileMenu(!toggleProfileMenu);
                                    setToggleNotification(false);
                                }}
                            >
                                {getInitials(user.fullName)}
                            </span>
                        )}
                        {toggleProfileMenu && <ProfileDropdown close={setToggleProfileMenu} />}
                    </>
                )}
            </div>
        </nav>
    );
};

const LoginDropDown = () => {
    const dispatch = useDispatch();

    return ReactDOM.createPortal(
        <div>
            <div
                className={`rounded w-[200px] absolute right-[20px] mt-[0px] top-[60px] z-[300] flex flex-col bg-white border border-gray-300 drop-shadow-md duration-300 border-t-[4px]  border-t-primary-dark max-md:hidden`}
            >
                <ul className="flex flex-col space-y-[15px] py-[20px] px-[20px] relative bg-white">
                    <Link to={ROUTES.LOGIN} onClick={() => dispatch(setRole('member'))}>
                        <li className="hover:text-primary-dark cursor-pointer">Affiliate Login</li>
                    </Link>
                    <Link to={ROUTES.LOGIN} onClick={() => dispatch(setRole('expert'))}>
                        <li className="hover:text-primary-dark cursor-pointer">Expert Login</li>
                    </Link>
                    <Link to={ROUTES.LOGIN} onClick={() => dispatch(setRole('achiever'))}>
                        <li className="hover:text-primary-dark cursor-pointer">Achiever Login</li>
                    </Link>
                </ul>
            </div>
        </div>,
        document.querySelector('.dropdown-container')
    );
};

export default Nav;
