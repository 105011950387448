export const ROUTES = Object.freeze({
    LOGIN: '/login',
    REGISTER: '/register',
    RECOVER: '/recover-password',

    ADMIN: '/dashboard/admin',
    ADMIN_LOGIN: '/tli-admin/login',
    ADMIN_TRAINING: '/dashboard/admin/training',
    TRAINING_COURSE: '/training/:trainingId',

    HOMEPAGE: '/dashboard',
    HOMELANDING: '/',
    ABOUTUS: '/about-us',
    CONTACTUS: '/contact-us',
    PRICING: '/pricing',
    BLOG: '/blog',
    COURSES: '/courses',

    STUDENT: '/dashboard/student',
    STUDENT_CERTIFICATE: '/dashboard/student/certificate',
    COURSE_PREVIEW: '/course-preview/:courseId',
    COURSE_LEARNING: '/dashboard/course-learning/:name',
    MY_COURSES: '/dashboard/my-courses',
    PROFILE: '/dashboard/profile',
    QUIZ: '/dashboard/quiz',
    FORUMS: '/dashboard/forum',
    FORUM: '/dashboard/forum/:forumId',

    NOTIFICATIONS: '/dashboard/notifications',

    TUTOR: '/dashboard/expert',
    TUTOR_COURSES: '/dashboard/expert/courses',
    TUTOR_EARNING: '/dashboard/expert/earning',
    TUTOR_PAYOUT: '/dashboard/expert/payout',
    TUTOR_STATEMENTS: '/dashboard/expert/statements',
    TUTOR_CREATE_COURSE: '/dashboard/expert/create-course',
    TUTOR_PAYMENT: '/dashboard/expert/payment',

    CART: '/cart',
    SAVED_COURSES: '/dashboard/student/saved',

    MEMBER: '/dashboard/affiliate',
    MEMBER_TRAINING: '/dashboard/affiliate/training',
    MEMBER_PAYMENT: '/dashboard/affiliate/payment',
});
